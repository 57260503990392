<template>
  <div id="portals-access" :style="{ width: portalWidth }">
    <!-- header -->

    <div class="header">
      <div class="col-auto logo">
        <img :src="_logo" />
      </div>

      <q-space />

      <BaseActionButton
        is-flat
        :icon="isMaximized ? 'eva-collapse' : 'eva-expand'"
        @click="toggleWidth"
      />
      <ThemeSwitcher class="q-ml-sm" :no-border="false" />
    </div>

    <!-- ... -->

    <!-- content -->
    <div class="content">
      <div class="row">
        <!-- breadcrumbs -->

        <Breadcrumbs
          v-if="breadcrumbs.length"
          :breadcrumbs="breadcrumbs"
          class="col q-ma-md"
          @click="handleBreadcrumbClick"
          @reset="resetList"
        />

        <!-- ... -->
      </div>

      <BaseScrollbar
        :height="
          breadcrumbs.length ? 'calc(100vh - 205px)' : 'calc(100vh - 160px)'
        "
      >
        <div class="q-pa-sm">
          <div v-if="breadcrumbs.length === 0">
            <div class="col welcomeHeader">
              <p class="row welcomeTextHigh">Hello,</p>
              <p class="row welcomeTextHigh">
                {{ $store.state.session.email.split("@")[0] }}!
              </p>
              <p class="row welcomeTextSmall">Welcome back,</p>
              <p class="row welcomeTextSmall">How can we help you today?</p>
            </div>

            <div class="col">
              <div
                v-for="item in drilledList"
                :key="item.id"
                :class="'col-' + item.size"
                class="mainMenu q-mb-sm"
              >
                <div class="row title">
                  {{ item.description }}
                </div>
                <div class="row q-mt-sm">
                  <q-space />
                  <div class="col-auto list-item" @click="drillDown(item)">
                    {{ item.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="breadcrumbs.length === 1" class="row q-col-gutter-sm">
            <div
              v-for="item in drilledList"
              :key="item.id"
              :class="'col-' + item.size"
            >
              <div v-if="item.label !== 'Document Submission'" class="subMenu">
                <div class="list-item">
                  {{ item.label }}
                </div>
                <div v-if="item.description" class="col menuDescription">
                  <q-list v-for="points in item.description" :key="points.id">
                    <q-item :class="points.class">
                      <q-item-section>
                        <q-item-label> {{ points.title }} </q-item-label>
                        <q-item-label caption>{{ points.label }}</q-item-label>
                        <template v-for="list in points.list">
                          <q-item-label
                            :key="list.id"
                            class="text-red q-pt-sm q-pl-sm"
                          >
                            {{ list.label }}
                            <template v-if="list.link">
                              <a
                                :href="list.link.value"
                                class="text-xs row q-ml-sm q-mt-xs"
                                target="_blank"
                                >{{ list.link.label }}</a
                              >
                            </template>
                          </q-item-label>
                        </template>
                      </q-item-section>
                    </q-item>
                    <q-separator spaced inset />
                  </q-list>
                  <div class="menuAction q-ma-sm">
                    <BaseButton
                      v-if="item.workflowId"
                      label="Proceed"
                      class="action"
                      @click="drillDown(item)"
                    />
                  </div>
                </div>
              </div>
              <div v-else class="mainMenu">
                <div
                  v-for="points in item.description"
                  :key="points.id"
                  class="q-mb-sm"
                >
                  <div class="row" :class="points.size">
                    {{ points.label }}
                  </div>
                </div>
                <div class="row q-mt-sm">
                  <q-space />
                  <div class="col-auto list-item" @click="drillDown(item)">
                    Upload completed claim form
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="
              activeItem.workflowId &&
              activeItem.label !== 'Track your claim' &&
              activeItem.label !== 'Track your request'
            "
            class="subcontent row"
          >
            <div class="col">
              <BaseScrollbar height="calc(100vh - 230px)">
                <div class="q-pl-md q-pr-md">
                  <RenderForm
                    v-if="formId"
                    :form-id="formId"
                    :panels="panels"
                    :secondary-panels="secondaryPanels"
                    :form-settings="formSettings"
                    :form-model="formModel"
                    :workflow-actions="actions"
                    :save-action.sync="saveAction"
                    :workflow-form-model.sync="workflowFormModel"
                    :is-readonly="formEditAccess"
                    :form-edit-controls="formEditControls"
                    :form-visibility-access="formVisibilityAccess"
                    :form-secure-controls="formSecureControls"
                    :enable-controls="enableControls"
                  />
                </div>
              </BaseScrollbar>
            </div>
            <div class="col-auto q-pa-xs leftBorder">
              <template v-for="tab in tabs">
                <template v-if="tab.label === 'attachments' && docWarning">
                  <BaseActionButton
                    :key="tab.id"
                    v-tooltip:red.top="'Attach required documents here'"
                    is-flat
                    no-border
                    :icon="tab.icon"
                    color="primary"
                    class="tabButtons"
                    @click="showLinks(tab.value)"
                  >
                    <template v-if="tab.label === 'attachments' && docWarning">
                      <span class="alert"
                        ><BaseIcon name="mdi-alert-circle" color="red" />
                      </span>
                    </template>
                    <template v-else-if="tab.count">
                      <span class="badge">{{ tab.count }} </span>
                    </template>
                  </BaseActionButton>
                </template>
                <template v-else>
                  <BaseActionButton
                    v-if="tab.label !== 'history'"
                    :key="tab.id"
                    is-flat
                    no-border
                    :icon="tab.icon"
                    color="primary"
                    class="tabButtons"
                    @click="showLinks(tab.value)"
                  >
                    <template v-if="tab.label === 'attachments' && docWarning">
                      <span class="alert"
                        ><BaseIcon name="mdi-alert-circle" color="red" />
                      </span>
                    </template>
                    <template v-else-if="tab.count">
                      <span class="badge">{{ tab.count }} </span>
                    </template>
                  </BaseActionButton>
                </template>
              </template>
            </div>
          </div>
          <div
            v-if="
              activeItem.workflowId &&
              (activeItem.label === 'Track your claim' ||
                activeItem.label === 'Track your request')
            "
          >
            <div class="row q-mb-sm">
              <q-space />
              <div class="col-auto q-mr-xs">
                <BaseActionButton
                  v-tooltip.top="'Refresh'"
                  icon="eva-refresh"
                  color="gray"
                  no-border
                  is-flat
                  size="20px"
                  @click="getRequestList"
                />
              </div>
              <div class="col-auto">
                <div id="global-search">
                  <BaseIcon name="eva-search" />

                  <div class="label">
                    <input
                      ref="searchQuery"
                      v-model="searchReq"
                      type="text"
                      class="q-px-xs"
                      placeholder="Search request..."
                      @keyup="searchList(search)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="subdetcontent">
              <BaseScrollbar height="calc(100vh - 260px)">
                <template v-for="request in workflowRequestList">
                  <div
                    v-if="request.visible"
                    :key="request.processId"
                    class="list q-mb-xs q-pa-sm"
                    :class="
                      request.processId === hasSelectedProcess
                        ? 'active-list'
                        : ''
                    "
                    @click="request.expand = !request.expand"
                  >
                    <div class="row">
                      <q-icon
                        name="mdi-star-outline"
                        color="primary"
                        class="q-mr-sm"
                        size="18px"
                      />
                      <span class="col">{{ request.requestNo }}</span>
                      <!-- <BaseIcon name="mdi-circle-small" color="secondary" />
                      <span class="col ellipsis">{{ request.stage }}</span> -->
                      <BaseIcon name="mdi-circle-small" color="secondary" />
                      <span class="col-auto q-mr-sm">{{
                        lastActionDuration(request)
                      }}</span>
                      <BaseActionButton
                        v-for="tab in tabs"
                        :key="tab.id"
                        v-tooltip.left="tab.label"
                        is-flat
                        no-border
                        :icon="tab.icon"
                        color="primary"
                        class="tabButtons q-mr-sm"
                        size="16px"
                        @click.stop="showLinks(tab.value, request)"
                      >
                        <template
                          v-if="tab.label === 'attachments' && docWarning"
                        >
                          <span class="alert"
                            ><BaseIcon name="mdi-alert-circle" color="red" />
                          </span>
                        </template>
                        <template v-else-if="tab.count">
                          <span class="badge">{{ tab.count }} </span>
                        </template>
                      </BaseActionButton>
                    </div>
                  </div>
                  <template v-if="request.expand">
                    <div :key="request.processId + 'det'" class="row">
                      <div class="col-12">
                        <q-stepper
                          ref="stepper"
                          alternative-labels
                          color="primary"
                          animated
                        >
                          <q-step
                            v-for="(level, index) in processLevel"
                            :key="level.id"
                            :name="index"
                            :title="level.label"
                            :prefix="index + 1"
                            :done="request.step >= index + 1"
                          >
                          </q-step>
                        </q-stepper>
                      </div>
                      <div class="col-12">
                        <div class="row justify-center">
                          <div class="col q-pa-md">
                            <div class="row items-center q-col-gutter-sm">
                              <template v-if="request.formData">
                                <template
                                  v-for="(value, key) in request.formData
                                    .fields"
                                >
                                  <template v-if="validateType(key)">
                                    <div :key="key" class="col-4 ellipsis">
                                      <div class="lblheader">
                                        {{ getLabel(key) }}
                                      </div>
                                      <div class="label">
                                        <div
                                          v-if="validateValue(value)"
                                          class="forms"
                                        >
                                          {{ removeTags(value) || "-" }}
                                        </div>
                                        <div v-else class="forms">
                                          <a :href="value" target="_blank">
                                            {{ value || "-" }}
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <table :key="key" class="q-ma-sm">
                                      <thead>
                                        <tr>
                                          <th
                                            v-for="(
                                              field_item, field_key
                                            ) in tableValue(value)[0]"
                                            :key="field_key"
                                          >
                                            {{ getLabel(field_key, "table") }}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="(
                                            field_item, field_key
                                          ) in tableValue(value)"
                                          :key="field_key"
                                        >
                                          <td
                                            v-for="(
                                              row_value, row_key
                                            ) in field_item"
                                            :key="row_key"
                                          >
                                            {{ row_value }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </template>
                                </template>
                              </template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </BaseScrollbar>
            </div>
          </div>
        </div>
      </BaseScrollbar>
    </div>

    <!-- ... -->

    <!-- footer -->

    <div class="footer">
      <!-- <div class="label">Powered by</div>
      <img src="@/assets/logo/logo-light.png" alt="logo" /> -->
      <div class="footer">&copy; 2024 All Rights Reserved</div>
    </div>

    <!-- ... -->

    <Sheet
      v-model="attachmentSheet"
      width="30vw"
      :sub-actions="checkListTemplate"
      :sub-actions-height="105"
      @input="closeAttachmentsSheet"
    >
      <!-- title -->

      <template #title> Attachments </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'refresh'"
          icon="mdi-refresh"
          color="secondary"
          no-border
          is-flat
          size="20px"
          @click="getAttachments"
        />

        <BaseActionButton
          v-tooltip.top="'upload documents'"
          icon="mdi-upload"
          color="secondary"
          no-border
          is-flat
          size="20px"
          class="q-mr-sm"
          @click="
            {
              rowIndex = null;
              $refs.attachments.click();
            }
          "
        />
      </template>

      <template #subActions>
        <div class="q-mt-sm">
          <div class="row q-mb-sm">
            <div class="q-mt-xs">Checklist Documents</div>
            <q-space />
            <div @click="downloadAll">
              <q-chip outline color="grey" class="chips grey-hover large">
                Download All
              </q-chip>
            </div>
          </div>
          <div class="row q-mb-md description text-sm text-grey">
            It can be useful to ensure that all necessary information and
            supporting documents are included when submitting a claim
          </div>
        </div>
      </template>

      <!-- content -->
      <div id="content-wrapper">
        <input
          ref="attachments"
          type="file"
          class="hidden"
          @change="uploadAttachments"
        />
        <template v-for="row in checkList">
          <template v-if="row.name">
            <div v-if="!row.attach" :key="row.uid" class="checkList">
              <div class="row">
                <div class="col-auto">
                  <BaseIcon name="mdi-file-check-outline" color="primary" />
                </div>
                <div class="col q-ml-sm">
                  <div class="row">
                    <div class="col-12">
                      {{ row.name }}
                    </div>
                    <div v-if="row.required" class="col-12 q-mt-sm">
                      <q-chip outline color="red" class="chips small">
                        Required
                      </q-chip>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="col-12" @click="attachTemplate(row.uid)">
                    <q-chip
                      outline
                      color="primary"
                      class="chips primary-hover medium"
                    >
                      <q-avatar
                        icon="eva-upload-outline"
                        class="q-mr-sm"
                        color="primary"
                        text-color="white"
                      />
                      Upload
                    </q-chip>
                  </div>
                  <div
                    v-if="row.template"
                    class="col-12"
                    @click="download(row.id)"
                  >
                    <q-chip
                      outline
                      color="secondary"
                      class="chips secondary-hover medium"
                    >
                      <q-avatar
                        icon="eva-download-outline"
                        class="q-mr-sm"
                        color="secondary"
                        text-color="white"
                      />
                      Download
                    </q-chip>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-for="file in attachmentList">
          <div :key="file.id" class="attachmentList">
            <div class="title row">
              <div v-if="selectedProcess.processId" class="col-auto">
                <!-- <CheckboxField v-model="file.checked" class="col-auto" /> -->
                <BaseIcon
                  :name="
                    file.checked
                      ? 'eva-checkmark-circle-2'
                      : 'eva-radio-button-off-outline'
                  "
                  color="secondary"
                  class="q-mr-sm"
                  @click="file.checked = !file.checked"
                />
              </div>
              <div class="col-auto">
                <FileIcon
                  :mime-type="fileType(file.name)"
                  class="mini-avatar q-mr-sm"
                />
              </div>
              <div class="col ellipsis" @click="openFile(file)">
                {{ file.name }}
              </div>
              <div class="col-auto">
                <BaseActionButton
                  v-tooltip.top="'delete'"
                  icon="eva-trash-2-outline"
                  color="red"
                  no-border
                  is-flat
                  size="20px"
                  @click.stop="attachmentDelete(file)"
                />
              </div>
            </div>

            <div class="meta">
              <!-- created at -->

              <div>{{ $day.format(file.createdAt) }}</div>

              <!-- ... -->

              <!-- created by -->

              <template v-if="file.createdBy">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ file.createdByEmail }}</div>
              </template>

              <!-- ... -->

              <!-- size -->

              <!-- <template v-if="file.size">
                <BaseIcon name="mdi-circle-small" />
                <div>{{ fileSize(file.size) }}</div>
              </template> -->

              <!-- ... -->
            </div>
          </div>
        </template>
        <div class="q-mt-sm defaultBottom">
          <BaseIcon name="mdi-circle-medium" color="red" /> Required Documents
        </div>
      </div>
    </Sheet>
    <Sheet
      v-model="commentsSheet"
      width="30vw"
      height="80vh"
      custom-height="30"
      @input="closeCommentsSheet"
    >
      <!-- title -->

      <template #title> Comments </template>

      <!-- ... -->

      <!-- content -->
      <div id="content-wrapper">
        <BaseScrollbar height="calc(100vh - 155px)">
          <!-- comments -->
          <template v-for="(row, idx) in commentsList">
            <q-chat-message
              :key="idx"
              :name="
                row.createdBy === $store.state.session.id
                  ? 'me'
                  : row.createdByEmail
              "
              :text="[row.comments]"
              text-html
              :sent="row.createdBy === $store.state.session.id"
              :stamp="lastCommentDuration(row)"
              :bg-color="
                row.createdBy === $store.state.session.id
                  ? 'primary-1'
                  : 'secondary-1'
              "
              class="q-ma-sm"
            />
          </template>
          <!-- ... -->
        </BaseScrollbar>
      </div>

      <!-- ... -->

      <div class="col q-pa-sm borderTop">
        <div class="row">
          <div class="col">
            <TextAreaField
              v-model="commentText"
              auto-focus
              placeholder="Type a new comment..."
            />
          </div>
          <div class="col-auto" style="margin: auto">
            <BaseActionButton
              v-tooltip.left="'Post Comments'"
              icon="eva-paper-plane-outline"
              color="primary-11"
              class="q-ml-sm commentAction"
              size="16px"
              rotate-class="rotate-45"
              @click="saveComment"
            />
            <BaseActionButton
              v-tooltip.left="'Advanced Comments'"
              icon="mdi-comment-text-multiple"
              color="primary-11"
              class="q-ml-sm q-mt-sm commentAction"
              size="16px"
              @click="advancedCommentsSheet = true"
            />
          </div>
        </div>
      </div>
    </Sheet>
    <Sheet
      v-model="fileSheet"
      no-padding
      width="100vw"
      @input="fileSheet = false"
    >
      <!-- title -->

      <template #title>
        <div class="attachmentFileHeader">
          <div class="avatar q-ml-sm">
            <FileIcon :mime-type="fileType(selectedFile.name)" />
          </div>

          <div class="q-ml-md">
            <div class="filename">
              {{ selectedFile.name }}
            </div>

            <div class="meta">
              <div>Archived At: {{ $day.format(selectedFile.createdAt) }}</div>
              <BaseIcon name="mdi-circle-small" />
              <div>Archived By: {{ selectedFile.createdByEmail }}</div>
              <div v-if="selectedFile.size">
                <BaseIcon name="mdi-circle-small" />
                File Size: {{ fileSize(selectedFile.size) }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #actions>
        <BaseActionButton
          v-tooltip.top="'Actions'"
          is-flat
          icon="eva-menu"
          class="q-mr-sm"
        >
          <q-menu
            transition-show="scale"
            transition-hide="scale"
            anchor="bottom end"
            self="top right"
          >
            <BaseScrollbar width="240px" class="q-pb-sm">
              <!-- columns -->

              <ListItem
                v-for="action in documentActionList"
                :key="action.id"
                :icon="action.icon"
                :label="action.label"
                @click="documentAction(action)"
              >
              </ListItem>
            </BaseScrollbar>

            <!-- ... -->
          </q-menu>
        </BaseActionButton>
      </template>

      <div class="attachmentFileFrame">
        <div class="file-list">
          <iframe
            id="file-frame"
            ref="myFiles"
            :src="fileLink()"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </Sheet>

    <Confirm
      v-model="successModalClaim"
      icon="eva-checkmark-circle-2"
      icon-color="green"
      size="24px"
      @input="
        {
          successModalClaim = false;
          refNo = '';
        }
      "
    >
      <template #title>Claim Registered!</template>

      <template #default>
        <div class="q-py-md text-lg">
          <p>
            Thank for submitting your Involuntary Unemployment claim to PSG.
          </p>
          <p>
            Your reference number is<span class="text-primary">
              "<b>{{ refNo }}"</b>.
            </span>
          </p>
          <p class="text-secondary">
            We have sent a link to the email address you provided which allows
            you to track your claim.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="primary"
          @click="
            {
              successModalClaim = false;
              refNo = '';
            }
          "
        />
      </template>
    </Confirm>

    <Confirm
      v-model="successModal"
      icon="eva-checkmark-circle-2"
      icon-color="green"
      size="24px"
      @input="
        {
          successModal = false;
          refNo = '';
        }
      "
    >
      <template #title>Request Registered!</template>

      <template #default>
        <div class="q-py-md text-lg">
          <p>Thank for submitting your Request.</p>
          <p>
            Your reference number is<span class="text-primary">
              "<b>{{ refNo }}"</b>.
            </span>
          </p>
          <p class="text-secondary">
            We have sent a link to the email address you provided which allows
            you to track your request.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          label="Close"
          color="primary"
          @click="
            {
              successModal = false;
              refNo = '';
            }
          "
        />
      </template>
    </Confirm>

    <Confirm
      v-model="alertModalClaim"
      icon="mdi-file-alert"
      icon-color="red"
      @input="alertModalClaim = false"
    >
      <template #title>Documents Required!</template>

      <template #default>
        <div class="q-py-md text-md">
          <p>
            Are you sure you want to submit the claim registration without
            attaching the required documents.
          </p>

          <p class="text-secondary">
            After registration, you will get the link to track your claim.<br />From
            there, you can upload the required documents once you have them.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="alertModalClaim = false"
        />
        <BaseButton label="yes" color="primary" @click="initiateRequest" />
      </template>
    </Confirm>

    <Confirm
      v-model="alertModal"
      icon="mdi-file-alert"
      icon-color="red"
      @input="alertModal = false"
    >
      <template #title>Documents Required!</template>

      <template #default>
        <div class="q-py-md text-md">
          <p>
            Are you sure you want to submit the request without attaching the
            required documents.
          </p>

          <p class="text-secondary">
            After registration, you will get the link to track your request.<br />From
            there, you can upload the required documents once you have them.
          </p>
        </div>
      </template>

      <template #footer>
        <BaseButton
          is-flat
          label="no"
          class="q-mr-sm"
          @click="alertModal = false"
        />
        <BaseButton label="yes" color="primary" @click="initiateRequest" />
      </template>
    </Confirm>

    <Modal
      v-model="advancedCommentsSheet"
      width="60vw"
      height="80vh"
      has-footer
      @input="advancedCommentsSheet = false"
    >
      <!-- title -->

      <template #title> Comments</template>

      <!-- ... -->

      <template #default>
        <TextBuilderField v-model="advancedCommentText" />
      </template>

      <template #footer>
        <BaseButton
          label="Post"
          icon="eva-paper-plane-outline"
          color="primary"
          @click="saveAdvancedComment"
        />
      </template>
    </Modal>

    <ProcessHistory
      v-if="historyProcessId"
      :process-id="historyProcessId"
      :selected-process="selectedProcess"
      @close="historyProcessId = 0"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Breadcrumbs from "./components/Breadcrumbs.vue";
import ThemeSwitcher from "@/layouts/app/components/toolbar/components/ThemeSwitcher.vue";
import RenderForm from "@/components/common/render-form/RenderForm.vue";
import Sheet from "@/components/common/popup/Sheet.vue";
import formatBytes from "@/helpers/format-bytes.js";
import { pdfSupport, htmlSupport, fileSupport } from "@/helpers/file-format.js";
import axios from "axios";
import FileIcon from "@/components/common/FileIcon.vue";
import TextAreaField from "@/components/common/form/text-area-field/TextAreaField.vue";
import Modal from "@/components/common/popup/Modal.vue";
import {
  portal,
  workflow,
  form,
  repository,
  uploadAndIndex,
  menu,
} from "@/api/factory.js";
import Confirm from "@/components/common/popup/Confirm.vue";
import TextBuilderField from "@/components/common/form/text-builder-field/TextBuilderField.vue";
import ListItem from "@/components/common/ListItem.vue";
import ProcessHistory from "@/components/common/workflow/ProcessHistory.vue";

export default {
  name: "PortalsAccessCustom",
  components: {
    ThemeSwitcher,
    Breadcrumbs,
    RenderForm,
    Sheet,
    FileIcon,
    TextAreaField,
    Modal,
    Confirm,
    TextBuilderField,
    ListItem,
    ProcessHistory,
  },

  props: {
    tenantId: {
      type: String,
      required: true,
    },

    portalId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      portalWidth: "720px",
      breadcrumbs: [],
      activeItem: "",
      tabList: [
        {
          id: this.$nano.id(),
          label: "Submit a claim",
          size: "12",
          description:
            "If you wish to initiate a new claim, kindly begin by clicking the 'Submit a claim' button below.",
          children: [
            {
              id: this.$nano.id(),
              label: "IUI (Involuntary Unemployment)",
              size: "6",
              workflowId: 13,
              description: [
                {
                  id: this.$nano.id(),
                  title: "Required Information",
                  class: "medium",
                  label:
                    "Please have the below documents ready to initiate an Involuntary Unemployment claim",
                  list: [
                    {
                      id: this.$nano.id(),
                      label: "- Record or Employment (ROE)",
                      condition: "OR",
                    },
                    {
                      id: this.$nano.id(),
                      label: "- Employer’s Statement (If ROE not available)",
                      link: {
                        label:
                          "Click here to download a pdf to be completed by your employer",
                        value:
                          "http://52.172.32.88/ezenterpriseAPI/template/Employer Statement.pdf",
                      },
                    },
                  ],
                },
                {
                  id: this.$nano.id(),
                  title: "Complete the Claim Form",
                  label:
                    "Fill out the claim form thoroughly, providing all the required information.",
                },
                {
                  id: this.$nano.id(),
                  title: "Follow Up",
                  label:
                    "Once you have submitted the claim form and the supporting documents, a PSG representative will contact you to follow up on the status of your claim. You will also receive a link by email which will allow you to track the progress of your claim.",
                },
              ],
            },
            {
              id: this.$nano.id(),
              label: "Sickness / Injury",
              size: "6",
              workflowId: 13,
              description: [
                {
                  id: this.$nano.id(),
                  title: "Required Information",
                  class: "medium",
                  label:
                    "All the below documents are required to initiate a Sickness/Injury Claim",
                  list: [
                    {
                      id: this.$nano.id(),
                      label: "- Employer’s Statement",
                    },
                    {
                      id: this.$nano.id(),
                      label: "- Physician’s Statement",
                    },
                  ],
                },
                {
                  id: this.$nano.id(),
                  title: "Complete the Claim Form",
                  label:
                    "Fill out the claim form thoroughly, providing all the required information.",
                },
                {
                  id: this.$nano.id(),
                  title: "Follow Up",
                  label:
                    "Once you have submitted the claim form and the supporting documents, a PSG representative will contact you to follow up on the status of your claim. You will also receive a link by email which will allow you to track the progress of your claim.",
                },
              ],
            },
            {
              id: this.$nano.id(),
              label: "Document Submission",
              size: "12",
              workflowId: 1013,
              description: [
                {
                  id: this.$nano.id(),
                  title: "",
                  size: "medium",
                  label:
                    "You can also download the claim documents by clicking on the link below and upload them to this portal.",
                },
                {
                  id: this.$nano.id(),
                  title: "",
                  size: "small",
                  label:
                    "Please note submitting forms through the below link might delay the process compared to submitting online forms on the link above.",
                },
              ],
            },
          ],
        },
        {
          id: this.$nano.id(),
          label: "Track your claim",
          size: "12",
          description:
            "To track the progress of your claim, simply click on the 'Track your claim' button below.",
          workflowId: 13,
        },
      ],
      drilledList: [],
      workflowId: "",
      workflow: {},
      formId: "",
      form: null,
      formModel: {},
      tabs: [
        {
          id: this.$nano.id(),
          label: "attachments",
          value: "ATTACHMENTS",
          icon: "mdi-attachment",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "comments",
          value: "COMMENTS",
          icon: "mdi-comment-text-outline",
          count: 0,
        },
        {
          id: this.$nano.id(),
          label: "history",
          value: "HISTORY",
          icon: "mdi-history",
          count: 0,
        },
      ],
      attachmentSheet: false,
      commentsSheet: false,
      commentText: "",
      commentsList: [],
      attachmentList: [],
      fileSheet: false,
      selectedFile: {},
      selectedProcess: {},
      checkList: [],
      docWarning: false,
      rowIndex: null,
      actions: [],
      workflowFormModel: {},
      saveAction: "",
      generatePDF: 0,
      workflowRequestList: [],
      searchReq: "",
      hasSelectedProcess: 0,
      processLevel: [],
      successModal: false,
      successModalClaim: false,
      refNo: "",
      alertModal: false,
      alertModalClaim: false,
      formEditAccess: false,
      formEditControls: [],
      formVisibilityAccess: true,
      formSecureControls: [],
      advancedCommentsSheet: false,
      advancedCommentText: "",
      documentActionList: [
        // {
        //   id: "Email_Sharing",
        //   key: "emailSharing",
        //   label: "Email Sharing",
        //   icon: "mdi-email-outline",
        // },
        {
          id: "Download",
          key: "download",
          label: "Download",
          icon: "mdi-download-outline",
        },
        {
          id: "Print",
          key: "print",
          label: "Print",
          icon: "eva-printer-outline",
        },
      ],
      historyProcessId: 0,
    };
  },

  computed: {
    ...mapState(["darkTheme"]),

    _logo() {
      return require(`@/assets/logo/name-${
        this.darkTheme ? "dark" : "light"
      }.png`);
    },

    isMaximized() {
      return this.portalWidth === "100%";
    },

    formName() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.name;
    },

    formDescription() {
      if (!this.formId) {
        return "";
      }

      return this.form.settings.general.description;
    },

    panels() {
      if (!this.formId) {
        return [];
      }

      return this.form.panels;
    },

    secondaryPanels() {
      if (!this.formId) {
        return [];
      }

      return this.form.secondaryPanels;
    },

    formSettings() {
      if (!this.formId) {
        return {};
      }
      return this.form.settings;
    },

    checkListTemplate() {
      if (this.checkList.length) {
        return this.checkList.filter((row) => row.template).length > 0;
      }
      return false;
    },

    _enableControls() {
      let enableControls = [];
      let listControls = [];
      this.panels.forEach((panel) => {
        if (panel.fields.length) {
          let panelFields = panel.fields.filter(
            (field) =>
              field.type === "SINGLE_SELECT" ||
              field.type === "SINGLE_CHOICE" ||
              field.type === "MULTIPLE_CHOICE" ||
              field.type === "MULTI_SELECT"
          );
          if (panelFields.length) {
            panelFields.forEach((row) => {
              if (row.settings.validation.enableSettings) {
                if (row.settings.validation.enableSettings.length) {
                  let controls = [];
                  row.settings.validation.enableSettings.forEach((tblrow) => {
                    if (tblrow.controls.length) {
                      controls.push({
                        value: tblrow.value,
                        childControls: tblrow.controls,
                      });
                      tblrow.controls.forEach((control) => {
                        if (controls.indexOf(control) < 0) {
                          listControls.push(control);
                        }
                      });
                    }
                  });
                  if (controls.length) {
                    enableControls.push({
                      id: row.id,
                      parentControls: controls,
                    });
                  }
                }
              }
            });
          }
        }
      });
      return {
        listControls: listControls,
        controls: enableControls,
      };
    },
  },

  watch: {
    workflowFormModel: {
      deep: true,
      immediate: true,
      handler() {
        if (this.workflowFormModel && this.saveAction) {
          this.checkData();
        }
      },
    },
  },

  created() {
    let origin = location.origin;
    if (
      origin === "https://demo.ezofis.com" ||
      origin === "http://52.172.32.88"
    ) {
      if (this.portalId == 1) {
        this.drilledList = this.tabList;
      } else {
        this.tabList = [];
        this.getPortal();
      }
    } else {
      this.tabList = [];
      this.getPortal();
    }
  },

  methods: {
    checkDocument() {
      this.docWarning = false;
      if (
        this.activeItem.label === "Track your claim" ||
        this.activeItem.label === "Track your request"
      ) {
        return;
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          if (row.required && !row.attach) {
            this.docWarning = true;
          }
        });
      }
    },

    toggleWidth() {
      this.portalWidth = this.portalWidth === "100%" ? "720px" : "100%";
    },

    drillDown(item) {
      this.breadcrumbs.push(item);
      this.activeItem = item;
      this.drilledList = this.getChildren(this.tabList);
      this.formId = "";
      this.form = null;
      this.workflowId = "";
      this.checkList = [];
      this.saveAction = "";
      this.actions = [];
      this.processLevel = [];
      this.docWarning = false;
      this.attachmentList = [];
      this.commentsList = [];
      if (this.activeItem.workflowId) {
        this.workflowId = this.activeItem.workflowId;
        this.getWorkflow();
      }
    },

    getChildren(items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === this.activeItem.id) {
          return items[i].children;
        }

        return [];
      }
    },

    handleBreadcrumbClick(breadcrumb) {
      const breadcrumbIdx = this.breadcrumbs.findIndex(
        (_breadcrumb) => _breadcrumb.id === breadcrumb.id
      );

      this.breadcrumbs.splice(breadcrumbIdx + 1);
      this.activeItem = breadcrumb;
      this.drilledList = this.getChildren(this.tabList);
    },

    resetList() {
      this.activeItem = {};
      this.breadcrumbs = [];
      this.drilledList = this.tabList;
    },

    showLinks(tab, process) {
      if (process) {
        this.selectedProcess = process;
      } else {
        this.selectedProcess = {};
      }
      if (tab === "ATTACHMENTS") {
        if (this.selectedProcess.processId) {
          this.attachmentList = [];
          this.getAttachments();
        } else {
          this.attachmentSheet = true;
        }
      } else if (tab === "COMMENTS") {
        if (this.selectedProcess.processId) {
          this.getComments();
        } else {
          this.commentsSheet = true;
        }
      } else if (tab === "HISTORY" && this.selectedProcess.processId) {
        this.historyProcessId = this.selectedProcess.processId;
      }
    },

    closeAttachmentsSheet() {
      this.attachmentSheet = false;
    },

    closeCommentsSheet() {
      this.closeCommnetsSheet = false;
    },

    lastCommentDuration(comment) {
      const date =
        comment.createdAt instanceof Date
          ? comment.createdAt
          : new Date(comment.createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    fileType(fileName) {
      if (fileName) return fileName.split(".").reverse()[0].slice(0, 4);
      else "";
    },

    fileSize(size) {
      return formatBytes(size);
    },

    fileLink() {
      let origin = location.origin.replace(
        "http://localhost:8080",
        "http://52.172.32.88"
      );
      if (this.selectedFile.name) {
        let ext = this.selectedFile.name.split(".").pop();
        if (this.selectedFile.initiate) {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
            }
          } else if (origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uploadId=${this.selectedFile.id}&type=1`;
          } else {
            return `${process.env.VUE_APP_API_URL}/uploadandindex/view/${this.$store.state.session.tenantId}/${this.selectedFile.id}/1`;
          }
        } else {
          if (pdfSupport(ext)) {
            if (
              origin === "https://ag-appsvc01.azurewebsites.net" ||
              origin === "https://ag-appsvc05.azurewebsites.net"
            ) {
              return `https://ag-appsvc04.azurewebsites.net/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2`;
            } else if (origin === "https://trial.ezofis.com") {
              return `https://trial.ezofis.com/docsviewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
            } else if (origin === "https://app.ezofis.com1") {
              return `https://ezdocsviewer.azurewebsites.ne/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2`;
            } else {
              return `${origin}/PDFViewer/web/viewer.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}&action=download,print`;
            }
          } else if (!htmlSupport(ext) && origin === "http://52.172.32.88") {
            return `http://52.172.32.88/DocsViewer/index.html?tId=${this.$store.state.session.tenantId}&uId=${this.$store.state.session.id}&rId=${this.selectedFile.repositoryId}&itemId=${this.selectedFile.id}&type=2&filename=${this.selectedFile.name}`;
          } else {
            return `${process.env.VUE_APP_API_URL}/file/view/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
          }
        }
      }
    },

    openFile(fileObj) {
      this.selectedFile = fileObj;
      let origin = location.origin;
      if (
        origin === "https://ag-appsvc01.azurewebsites.net" ||
        origin === "https://ag-appsvc05.azurewebsites.net"
      ) {
        window.open(this.fileLink());
      } else {
        this.fileSheet = true;
      }
    },

    download(id) {
      window.open(
        `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${id}`
      );
    },

    downloadAll() {
      this.checkList.forEach((row) => {
        if (row.id && row.template) {
          window.open(
            `${process.env.VUE_APP_API_URL}/workflow/viewChecklist?wId=${this.workflowId}&id=${row.id}`
          );
        }
      });
    },

    attachTemplate(uid) {
      this.rowIndex = uid;
      this.$refs.attachments.click();
    },

    searchList() {
      if (this.searchReq) {
        this.workflowRequestList.forEach((process) => {
          if (
            String(process.requestNo)
              .toLowerCase()
              .indexOf(this.searchReq.toLowerCase()) > -1
          ) {
            process.visible = true;
          } else {
            process.visible = false;
          }
        });
      } else {
        this.workflowRequestList.forEach((process) => {
          process.visible = true;
        });
      }
    },

    lastActionDuration(request) {
      const date =
        request.transaction_createdAt instanceof Date
          ? request.transaction_createdAt
          : new Date(request.transaction_createdAt);
      const formatter = new Intl.RelativeTimeFormat("en");
      const ranges = {
        years: 3600 * 24 * 365,
        months: 3600 * 24 * 30,
        weeks: 3600 * 24 * 7,
        days: 3600 * 24,
        hours: 3600,
        minutes: 60,
        seconds: 1,
      };
      const secondsElapsed = (date.getTime() - Date.now()) / 1000;
      for (let key in ranges) {
        if (ranges[key] < Math.abs(secondsElapsed)) {
          const delta = secondsElapsed / ranges[key];
          return formatter.format(Math.round(delta), key);
        }
      }
    },

    getLabel(id, type) {
      let controlLabel = "";
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (type === "table") {
            const column = field.settings.specific.tableColumns.find(
              (column) => column.id === id
            );
            if (column) {
              controlLabel = column.label;
            }
          } else {
            if (field.id === id) {
              controlLabel = field.label;
              return;
            }
          }
        }
      });
      return controlLabel;
    },

    validateType(key) {
      let proceed = true;
      this.form.panels.forEach((panel) => {
        if (!panel.fields.length) {
          return;
        }
        for (let field of panel.fields) {
          if (field.id === key) {
            if (field.type === "TABLE") {
              proceed = false;
              return;
            }
          }
        }
      });
      return proceed;
    },

    validateValue(value) {
      let urlPattern =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      return !urlPattern.test(value);
    },

    removeTags(value) {
      if (!value) return "";
      return value.replace(/(<([^>]+)>)/gi, "");
    },

    tableValue(value) {
      if (value) {
        return JSON.parse(value);
      }
      return [];
    },

    // API Functions

    async getPortal() {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await portal.getPortal(this.portalId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload) {
        this.tabList = [
          {
            id: this.$nano.id(),
            label: "Track your request",
            size: "12",
            description:
              "To track the progress of your request, simply click on the 'Track your request' button below.",
            workflowId: payload.workflowId,
          },
        ];
        this.drilledList = this.tabList;
        this.workflowId = payload.workflowId;
        this.getWorkflow(true);
      }
    },

    async getWorkflow(homePage) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await workflow.getWorkflow(this.workflowId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.workflow = payload;
      let workflowJson = JSON.parse(this.workflow.flowJson);
      let startBlock = workflowJson.blocks.find(
        (block) => block.type === "START"
      );
      if (startBlock) {
        if (startBlock.settings.initiateBy.indexOf("PORTAL") > -1) {
          if (homePage) {
            this.tabList = [
              {
                id: this.$nano.id(),
                label: "Submit a request",
                size: "12",
                description:
                  "If you wish to initiate a new request, kindly begin by clicking the 'Submit a request' button below.",
                workflowId: this.workflowId,
              },
              {
                id: this.$nano.id(),
                label: "Track your request",
                size: "12",
                description:
                  "To track the progress of your request, simply click on the 'Track your request' button below.",
                workflowId: this.workflowId,
              },
            ];
            this.drilledList = this.tabList;
          }
          if (startBlock.settings.fileSettings) {
            if (startBlock.settings.fileSettings.fileCheckList.length) {
              this.checkList = startBlock.settings.fileSettings.fileCheckList;
              this.checkList.forEach((row) => {
                row.uid = this.$nano.id();
                row.attach = false;
              });
              this.checkDocument();
            }
          }
          let rules = workflowJson.rules.find(
            (rule) => rule.fromBlockId === startBlock.id
          );
          if (rules) {
            this.actions.push(rules.proceedAction);
          }
        }
        if (startBlock.settings.formEditAccess) {
          if (startBlock.settings.formEditAccess === "") {
            this.formEditAccess = true;
            this.formEditControls = [];
          } else if (startBlock.settings.formEditAccess === "FULL") {
            this.formEditAccess = false;
            this.formEditControls = [];
          } else if (startBlock.settings.formEditAccess === "PARTIAL") {
            this.formEditAccess = false;
            this.formEditControls = startBlock.settings.formEditControls;
          }
        }
        if (startBlock.settings.formVisibilityAccess) {
          this.formVisibilityAccess = true;
          this.formSecureControls = [];
        } else {
          this.formVisibilityAccess = false;
          this.formSecureControls = startBlock.settings.formSecureControls;
        }
        this.generatePDF = startBlock.settings.generatePDF ? 1 : 0;
      }
      if (workflowJson.settings.publish.publishOption === "PUBLISHED") {
        let fId = String(workflowJson.settings.general.initiateUsing.formId);
        if (fId) {
          this.getForm(fId);
        }
      }
      workflowJson.blocks.forEach((block) => {
        if (
          block.type === "START" ||
          block.type === "INTERNAL_ACTOR" ||
          block.type === "END"
        ) {
          this.processLevel.push({
            id: block.id,
            label: block.settings.label,
          });
        }
      });
    },

    async getForm(fId) {
      this.$store.commit("showLoadingBar");

      const { error, payload } = await form.getForm(fId);

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.formId = fId;
      this.form = JSON.parse(payload.formJson);
      if (
        this.activeItem.label === "Track your claim" ||
        this.activeItem.label === "Track your request"
      ) {
        this.getRequestList();
      }
    },

    async getComments() {
      this.commentsList = [];
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getProcessComments(
        this.workflow.id,
        this.selectedProcess.processId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        this.commentsList = payload;
      }
      this.commentsSheet = true;
    },

    async saveComment() {
      if (this.commentText) {
        if (this.selectedProcess.processId) {
          this.$store.commit("showLoadingBar");
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.commentText,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.commentText = "";
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.commentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.commentText = "";
        }
      }
    },

    async saveAdvancedComment() {
      if (this.advancedCommentText) {
        if (this.selectedProcess.processId) {
          this.$store.commit("showLoadingBar");
          const { error } = await workflow.insertProcessComment(
            this.workflow.id,
            this.selectedProcess.processId,
            this.selectedProcess.transactionId,
            {
              comments: this.advancedCommentText,
              showTo: this.commentAction,
            }
          );

          this.$store.commit("hideLoadingBar");

          if (error) {
            this.$alert.error(error);
            return;
          }
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
          this.getComments();
        } else {
          this.commentsList.push({
            comments: this.advancedCommentText,
            createdAt: new Date().toISOString(),
            createdBy: this.$store.state.session.id,
          });
          this.tabs.find((tab) => {
            if (tab.value === "COMMENTS") {
              tab.count = this.commentsList.length;
            }
          });
          this.advancedCommentText = "";
          this.advancedCommentsSheet = false;
        }
      }
    },

    async uploadAttachments(e) {
      if (this.selectedProcess.processId) {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("workflowId", this.workflow.id);
        formData.append("repositoryId", this.workflow.repositoryId);
        formData.append("processId", this.selectedProcess.processId);
        formData.append("transactionId", this.selectedProcess.transactionId);
        formData.append("fields", "");
        formData.append("file", file);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.rowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.rowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        this.$store.commit("showLoadingBar");
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/workflow/attachmentWithProcessId`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });
          const { status, data } = response;
          if (status === 200 && data) this.$alert.success(`Document attached`);
          else throw response;
        } catch (error) {
          this.$alert.error("Error uploading file");
          console.error(error);
        }
        this.$store.commit("hideLoadingBar");
        if (this.checkList.length) {
          this.checkList.forEach((row) => {
            if (row.uid === this.rowIndex) {
              row.attach = true;
              return;
            }
          });
          this.checkDocument();
        }
        e.target.value = "";
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("repositoryId", this.workflow.repositoryId);
        let filename = e.target.files[0].name;
        const ext = filename.split(".").pop();
        if (this.rowIndex) {
          let currentDoc = this.checkList.filter(
            (row) => row.uid === this.rowIndex
          );
          formData.append("filename", currentDoc[0].name + "." + ext);
          filename = currentDoc[0].name + "." + ext;
        }
        if (!fileSupport(ext)) {
          this.$alert.info("This file format is not supported");
          return;
        }
        try {
          const response = await axios({
            url: `${process.env.VUE_APP_API_URL}/uploadAndIndex/upload`,
            method: "POST",
            data: formData,
            headers: {
              Token: this.$store.state.identity.token,
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          });

          const { status, data } = response;
          if (status !== 200) {
            throw "Invalid response";
          }
          let filesList = JSON.parse(data);
          this.attachmentList.push({
            id: Number(filesList.fileId),
            name: filename,
            createdAt: new Date().toISOString(),
            createdByEmail: this.$store.state.session.email,
            size: file.size,
            initiate: true,
            uid: this.rowIndex,
          });
          this.tabs.find((tab) => {
            if (tab.value === "ATTACHMENTS") {
              tab.count = this.attachmentList.length;
            }
          });
          if (this.checkList.length) {
            this.checkList.forEach((row) => {
              if (row.uid === this.rowIndex) {
                row.attach = true;
                return;
              }
            });
            this.checkDocument();
          }
          e.target.value = "";
        } catch (error) {
          console.error(error);
          this.$alert.error(`error attaching file`);
        }
      }
    },

    async getAttachments() {
      this.checkList = [];
      let workflowJson = JSON.parse(this.workflow.flowJson);
      let block = workflowJson.blocks.find(
        (block) => block.id === this.selectedProcess.activityId
      );
      if (block) {
        if (block.settings.fileSettings) {
          if (block.settings.fileSettings.fileCheckList.length) {
            this.checkList = block.settings.fileSettings.fileCheckList;
            this.checkList.forEach((row) => {
              row.uid = this.$nano.id();
              row.attach = false;
            });
            this.checkDocument();
          }
        }
      }
      let count = this.attachmentList.length;
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getAttachments(
        this.workflow.id,
        this.selectedProcess.processId
      );

      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }
      if (payload.length) {
        if (count !== payload.length) {
          this.attachmentList = payload.map((item) => {
            return {
              ...item,
              checked: false,
            };
          });
        }
      } else {
        this.attachmentList = [];
      }
      if (this.checkList.length) {
        this.checkList.forEach((row) => {
          let filterAttach = this.attachmentList.filter((file) => {
            let filename = file.name.substr(0, file.name.lastIndexOf("."));
            if (row.name === filename) {
              return file;
            }
          });
          if (filterAttach.length) {
            row.attach = true;
          } else {
            row.attach = false;
          }
        });
      }
      this.attachmentSheet = true;
    },

    async attachmentDelete(file) {
      this.$store.commit("showLoadingBar");
      if (file.initiate) {
        const { error } = await uploadAndIndex.deleteUploadedFiles([file.id]);
        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$store.commit("hideLoadingBar");
        this.attachmentList = this.attachmentList.filter(
          (row) => row.id !== file.id
        );
        this.checkList.forEach((row) => {
          if (row.uid === file.uid) {
            row.attach = false;
          }
        });
      } else {
        const { error } = await repository.fileDelete(
          this.workflow.repositoryId,
          file.id
        );
        if (error) {
          this.$alert.error(error);
          return;
        }
        this.$store.commit("hideLoadingBar");
        this.getAttachments();
      }
    },

    checkData() {
      let docRequired = [];
      if (this.docWarning) {
        docRequired = this.checkList.filter(
          (row) => row.required && !row.attach
        );
      }
      if (docRequired.length) {
        let origin = location.origin;
        if (
          origin === "https://demo.ezofis.com" ||
          origin === "http://52.172.32.88"
        ) {
          if (this.portalId == 1) {
            this.alertModalClaim = true;
          } else {
            this.alertModal = true;
          }
        } else {
          this.alertModal = true;
        }
      } else {
        this.initiateRequest();
      }
    },

    async initiateRequest() {
      let uploadedIdList = [];
      if (this.attachmentList.length) {
        this.attachmentList.forEach((file) => {
          uploadedIdList.push(file.id);
        });
      }

      let docRequired = [];
      if (this.docWarning) {
        docRequired = this.checkList.filter(
          (row) => row.required && !row.attach
        );
      }
      // let defaultCommentText =
      //   "Request submitted portal " + window.location.href;
      // this.commentsList.push({
      //   comments: defaultCommentText,
      //   createdAt: new Date().toISOString(),
      //   createdBy: this.$store.state.session.id,
      // });

      let inputData = {
        workflowId: this.workflow.id,
        review: this.saveAction,
        comments: this.commentsList,
        formData: {
          formId: this.formId,
          fields: this.workflowFormModel,
        },
        fileIds: uploadedIdList,
        fileChecklistStatus: this.docWarning ? 1 : 0,
        fileInfo: docRequired,
        portalId: this.portalId,
        hasFormPDF: this.generatePDF,
      };
      this.saveAction = "";

      this.$store.commit("showLoadingBarSave");
      const { error, payload } = await workflow.processTransaction(inputData);
      this.$store.commit("hideLoadingBarSave");

      if (error) {
        this.$alert.error(error);
        return;
      }
      this.alertModal = false;
      this.workflowFormModel = {};
      this.$alert.success(`${payload.requestNo} Request Initiated`);
      this.refNo = payload.requestNo;
      let origin = location.origin;
      if (
        origin === "https://demo.ezofis.com" ||
        origin === "http://52.172.32.88"
      ) {
        if (this.portalId == 1) {
          this.successModalClaim = true;
        } else {
          this.successModal = true;
        }
      } else {
        this.successModal = true;
      }

      this.resetList();
    },

    async getRequestList() {
      this.workflowRequestList = [];
      await this.getInboxList();
      await this.getSentList();
      await this.getCompletedList();
    },

    async getSentList() {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getSentList(this.workflow.id, {
        filterBy: [],
        itemsPerPage: 20,
        currentPage: 1,
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          let stepCount = 0;
          let workflowJson = JSON.parse(this.workflow.flowJson);
          let check = true;
          workflowJson.blocks.forEach((block) => {
            if (block.id !== process.activityId && check) {
              stepCount += 1;
            } else {
              if (process.flowStatus == 1) {
                stepCount += 1;
              }
              check = false;
            }
          });
          this.workflowRequestList.push({
            ...process,
            step: stepCount,
            visible: true,
            expand: false,
          });
        });
      });
    },

    async getCompletedList() {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getCompletedList(
        this.workflow.id,
        {
          filterBy: [],
          itemsPerPage: 20,
          currentPage: 1,
        }
      );
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          let stepCount = 0;
          let workflowJson = JSON.parse(this.workflow.flowJson);
          let check = true;
          workflowJson.blocks.forEach((block) => {
            if (block.id !== process.activityId && check) {
              stepCount += 1;
            } else {
              if (process.flowStatus == 1) {
                stepCount += 1;
              }
              check = false;
            }
          });
          this.workflowRequestList.push({
            ...process,
            step: stepCount,
            visible: true,
            expand: false,
          });
        });
      });
    },

    async getInboxList() {
      this.$store.commit("showLoadingBar");
      const { error, payload } = await workflow.getInboxList(this.workflow.id, {
        filterBy: [],
        itemsPerPage: 20,
        currentPage: 1,
      });
      this.$store.commit("hideLoadingBar");

      if (error) {
        this.$alert.error(error);
        return;
      }

      payload.data.forEach((row) => {
        row.value.forEach((process) => {
          let stepCount = 0;
          let workflowJson = JSON.parse(this.workflow.flowJson);
          let check = true;
          workflowJson.blocks.forEach((block) => {
            if (block.id !== process.activityId && check) {
              stepCount += 1;
            } else {
              if (process.flowStatus == 1) {
                stepCount += 1;
              }
              check = false;
            }
          });
          this.workflowRequestList.push({
            ...process,
            step: stepCount,
            visible: true,
            expand: false,
          });
          this.workflowRequestList.sort(
            (a, b) =>
              new Date(b.transaction_createdAt) -
              new Date(a.transaction_createdAt)
          );
        });
      });
    },

    documentAction(action) {
      if (action.id === "Email_Sharing") {
        //
      } else if (action.id === "Print") {
        if (this.selectedFile.name) {
          let ext = this.selectedFile.name.split(".").pop();
          if (pdfSupport(ext)) {
            localStorage.setItem("filePrint", true);
            localStorage.setItem("filePrintId", this.selectedFile.id);
          } else {
            this.$refs.myFiles.contentWindow.print();
          }
        }
        this.filePrintSession();
      } else if (action.id === "Download") {
        let link = "";
        link = `${process.env.VUE_APP_API_URL}/menu/file/download/${this.$store.state.session.tenantId}/${this.$store.state.session.id}/${this.selectedFile.repositoryId}/${this.selectedFile.id}/2`;
        window.open(link, "_blank");
        //this.$alert.success(`file downloaded successfully`);
      }
    },

    async filePrintSession() {
      const { error } = await menu.filePrintSession({
        repositoryId: this.selectedFile.repositoryId,
        itemId: this.selectedFile.id,
        activityId: 4,
      });

      if (error) {
        this.$alert.error(error);
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.theme-light {
  .list-item {
    background-color: rgba(0, 188, 212, 0.15);
  }

  #portals-access .list {
    background-color: #e1f5f9;
    &:hover {
      background-color: $secondary-2 !important;
    }
  }

  .q-stepper {
    background: white !important;
  }
}

.theme-dark {
  .list-item {
    background-color: rgba(38, 197, 218, 0.15);
  }

  #portals-access .list {
    &:hover {
      background-color: var(--secondary) !important;
    }
  }

  .q-stepper {
    background: none !important;
  }

  .menuDescription .text-caption {
    color: white !important;
  }
}
#portals-access {
  margin: auto;
  padding: 16px;

  .logo img {
    height: 24px;
    width: auto;
  }

  .welcomeHeader {
    padding: 16px;
    border-radius: 4px;
    background-color: #8c52ff;
    margin-bottom: 16px;
    align-items: center;
    color: #fff;

    .welcomeTextHigh {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 24px !important;
    }

    .welcomeTextSmall {
      font-size: 20px;
      margin-bottom: 10px !important;
    }
  }

  .header {
    padding: 16px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    border-top: 4px solid var(--primary);
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .description {
      @extend .text-sm;
      color: var(--icon-color-inverted);
    }
  }

  .footer {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    bottom: 16px;
    width: 100%;

    .label {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 4px;
    }

    img {
      height: 16px;
      width: auto;
    }
  }

  .content {
    border-radius: 4px;
    background-color: var(--component-bg-color);
    height: calc(100vh - 160px);

    .mainMenu {
      padding: 15px;
      border: 1px solid var(--secondary);
      border-radius: 4px;

      .title {
        font-size: 16px;
      }

      .list-item {
        height: 36px;
      }

      .medium {
        font-size: 14px;
      }

      .small {
        font-size: 12px;
      }

      &:hover {
        background-color: $secondary-1;
      }
    }

    .subMenu:hover {
      background-color: $secondary-1;
    }

    .list-item {
      height: 48px;
      border-radius: 4px;
      padding: 16px;
      font-weight: 500;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      user-select: none;
      background-color: rgba(0, 188, 212, 0.15);

      &:hover {
        cursor: pointer;
        color: white;
        background-color: var(--secondary);
      }
    }

    .menuDescription {
      border: 1px solid var(--divider-color);

      .medium {
        height: 160px;
        .justify-center {
          justify-content: normal;
        }
      }
    }

    .menuAction .action {
      margin: auto;
    }

    .subcontent,
    .subdetcontent {
      border-radius: 4px;
      border: 1px solid var(--divider-color);

      .leftBorder {
        border-left: 1px solid var(--divider-color);
      }

      .badge {
        -webkit-border-radius: 9px;
        -moz-border-radius: 9px;
        border-radius: 9px;
        font-size: 12px;
        background: $primary;
        color: #fff;
        padding: 0 5px;
        vertical-align: top;
        height: 18px;
        margin-top: -20px;
        margin-right: -20px;
        position: absolute;
        z-index: 1;
      }

      .alert {
        -webkit-border-radius: 9px;
        -moz-border-radius: 9px;
        border-radius: 9px;
        font-size: 12px;
        padding: 0 5px;
        vertical-align: top;
        height: 18px;
        margin-top: -20px;
        margin-right: -20px;
        position: absolute;
        z-index: 1;
      }
    }

    .subdetcontent {
      #action-btn.tabButtons {
        height: 24px;
        width: 24px;
      }

      .list {
        border-radius: 6px;
        height: 100%;
        border: 1px solid #e1f5f9;
        cursor: pointer;
        font-size: 12px;

        &.active-list {
          background-color: $secondary-2 !important;
        }
      }

      .forms {
        border-radius: 6px;
        height: 100%;
        border: 1px solid #eee;
        padding: 5px;
        text-align: center;
      }

      .lblheader {
        text-align: center;
        color: $primary-11;
        font-weight: 500;
      }

      table {
        table-layout: fixed;
        // width: 100%;
        border-collapse: collapse;
        white-space: nowrap;

        tr {
          height: 36px;

          th {
            color: $primary-11;
            text-align: left;
          }
        }

        th,
        td {
          border: 1px solid var(--divider-color);
          padding: 8px;
          font-weight: 500;
        }
      }

      .ellipsis:hover {
        overflow: visible;
        white-space: normal;
        word-break: break-all;
      }
    }
  }

  #global-search {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 0px 12px;
    color: var(--icon-color);
    border: 1px solid $secondary;
    // background-color: var(--body-bg-color);

    .label {
      @extend .text-sm;
      flex: 1;
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
  }
}

#sheet .defaultBottom {
  position: absolute;
  bottom: 0;
}

#sheet .attachmentList,
#sheet .checkList {
  padding: 12px;
  border-bottom: 1px solid var(--divider-color);
  &:hover {
    background: #e0f7fa;
  }

  // &:not(:last-child) {
  //   border-bottom: 1px solid var(--divider-color);
  // }

  &.is-selected.mini-mode::after {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    width: 4px;
    height: 75%;
    background-color: var(--secondary);
    border-radius: 4px 0px 0px 4px;
  }

  .thumbnail {
    height: 80px;
    width: 80px;
    border: 1px solid var(--divider-color);
    border-radius: 4px 0px 0px 4px;
  }

  .avatar {
    width: 48px;
    height: 48px;
  }

  .mini-avatar {
    width: 18px;
    height: 18px;
  }

  .title {
    font-weight: 600;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
  }

  #avatar {
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondary;
    border-radius: 100%;
    border: 1px solid;
  }
}

#sheet .attachmentFileHeader {
  display: flex;
  align-items: center;
  text-transform: none !important;
  .avatar {
    width: 36px;
    height: 36px;
  }

  .filename {
    font-size: 16px !important;
  }

  .meta {
    @extend .text-sm;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: var(--icon-color-inverted);
    font-weight: normal !important;
  }
}

#sheet .attachmentFileFrame {
  min-height: calc(100vh - 61px);
  background-color: var(--body-bg-color);
  display: flex;

  .file-list {
    flex: 1;
  }

  .aside {
    width: 380px;
    background-color: var(--component-bg-color);
  }

  #file-frame {
    width: 100%;
    height: 100%;
  }
}

#sheet {
  .chips {
    cursor: pointer;
    background-color: #fff !important;
    font-size: 12px !important;

    &.small {
      width: 75px;
    }

    &.medium {
      width: 95px;
    }
  }

  .primary-hover:hover {
    background: $primary-2 !important;
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .green-hover:hover {
    background: $green-2 !important;
  }

  .orange-hover:hover {
    background: $orange-2 !important;
  }

  .red-hover:hover {
    background: $red-2 !important;
  }

  .grey-hover:hover {
    background: $grey-2 !important;
  }
}

#modal #processHistory {
  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
    margin-bottom: 15px;

    tr {
      height: 36px;

      th {
        color: var(--secondary);
        text-align: left;
      }
    }

    th,
    td {
      border: 1px solid var(--divider-color);
      padding: 8px;
      font-weight: 500;
    }

    td.ellipsis:hover {
      overflow: visible;
      white-space: normal;
      word-break: break-all;
    }
  }
}
</style>
<style lang="scss">
#portals-access {
  .q-stepper {
    box-shadow: none !important;
    .q-stepper__content,
    .q-panel-parent {
      display: none !important;
    }
  }
  .q-panel-parent {
    display: none !important;
  }
}
#sheet {
  #action-btn.commentAction {
    height: 32px !important;
    width: 32px !important;
  }
}
</style>
